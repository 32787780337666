import { IResourceComponentsProps, useTranslate } from '@refinedev/core'

import { Table, TableProps } from 'antd'
import { IAgreement } from 'interfaces'

import { ShortId } from 'components/ShortId'
import { DeleteButton, TextField } from '@refinedev/antd'

const AgreementsTable: React.FC<
  IResourceComponentsProps & {
    tableProps: TableProps<IAgreement>
    hideMerchant?: Boolean
    hideAgent?: Boolean
  }
> = ({ tableProps, hideMerchant = false, hideAgent = false }) => {
  const t = useTranslate()

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={{
        ...tableProps.pagination,
        pageSize: 10,
        position: ['bottomLeft'],
        size: 'small',
      }}
    >
      <Table.Column<IAgreement>
        dataIndex="id"
        ellipsis
        key="id"
        title={t('agreements.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      {!hideMerchant && (
        <Table.Column<IAgreement>
          dataIndex={['merchant', 'name']}
          ellipsis
          key="merchant"
          title={t('agreements.fields.merchant')}
        />
      )}
      <Table.Column<IAgreement>
        dataIndex={'type'}
        ellipsis
        key="type"
        title={t('agreements.fields.type')}
        render={(value) => t(`agreements.types.${value}`)}
      />
      <Table.Column<IAgreement>
        dataIndex={'percent'}
        ellipsis
        key="percent"
        title={t('agreements.fields.percent')}
      />
      <Table.Column<IAgreement>
        dataIndex={['gateway', 'name']}
        ellipsis
        key="gateway"
        title={t('agreements.fields.gateway')}
        render={(value) => (
          <TextField
            style={{ color: value ? '' : 'grey' }}
            value={value ? value : 'Не указан'}
          />
        )}  
      />
      {!hideAgent && (
        <Table.Column<IAgreement>
          dataIndex={['agent', 'name']}
          ellipsis
          key="agent"
          title={t('agreements.fields.agent')}
        />
      )}
      <Table.Column<IAgreement>
        dataIndex={['partner', 'name']}
        ellipsis
        key="partner"
        title={t('agreements.fields.partner')}
      />
      <Table.Column<IAgreement>
        dataIndex="valid_from"
        ellipsis
        key="valid_from"
        title={t('agreements.fields.valid_from')}
      />
      <Table.Column<IAgreement>
        dataIndex="valid_to"
        ellipsis
        key="valid_to"
        title={t('agreements.fields.valid_to')}
      />
      <Table.Column<IAgreement>
        dataIndex="actions"
        ellipsis
        key="actions"
        render={(value, record) => (
          <DeleteButton
            size="middle"
            recordItemId={record.id}
            resource="billing/agreements"
          />
        )}
      />
    </Table>
  )
}

export default AgreementsTable
