import {
  CrudFilters,
  getDefaultFilter,
  IResourceComponentsProps,
  usePermissions,
  useTranslate,
  useCan,
} from '@refinedev/core'

import { List, ShowButton, TextField, useTable } from '@refinedev/antd'
import { Form, Input, Space, Table } from 'antd'
import { IMerchant } from 'interfaces'
import { formattedCurrencyAmount } from 'utils'
import { SearchOutlined } from '@ant-design/icons'
import { ShortId } from 'components/ShortId'
import BooleanTag from 'components/BooleanTag'

export const MerchantList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()

  const { data: canChangeAntifraudEnabled } = useCan({
    resource: 'merchants',
    action: 'change_antifraud_enabled',
  })

  const { data: canChangeDisputesEnabled } = useCan({
    resource: 'merchants',
    action: 'change_disputes_enabled',
  })

  const { data: canChangeAurisShopId } = useCan({
    resource: 'merchants',
    action: 'change_auris_shop_id',
  })

  const { tableProps, searchFormProps, filters } = useTable<IMerchant>({
    onSearch: (params: any) => {
      const filters: CrudFilters = []
      const { search } = params

      filters.push({
        field: 'search',
        operator: 'in',
        value: search,
      })

      return filters
    },

    pagination: {
      pageSize: 20,
    },
  })
  const { data } = usePermissions<string>({
    v3LegacyAuthProviderCompatible: true,
  })

  var timerId: any

  const debounceFunction = (func: any, delay: number) => {
    clearTimeout(timerId)
    timerId = setTimeout(func, delay)
  }

  const onValueChange = () => {
    debounceFunction(() => {
      searchFormProps.form?.submit()
    }, 400)
  }

  return (
    <List canCreate={data?.includes('admin')}>
      <Form
        {...searchFormProps}
        layout="vertical"
        size={'middle'}
        onValuesChange={onValueChange}
        initialValues={{
          search: getDefaultFilter('search', filters),
        }}
      >
        <Form.Item name="search">
          <Input
            placeholder={t('merchants.filter.search.placeholder')}
            prefix={<SearchOutlined />}
            allowClear
          />
        </Form.Item>
      </Form>
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          pageSize: 10,
          position: ['bottomLeft'],
          size: 'small',
        }}
      >
        <Table.Column<IMerchant>
          dataIndex="name"
          key="name"
          title={t('merchants.fields.name')}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column<IMerchant>
          dataIndex="balance"
          key="balance"
          title={t('merchants.fields.balance')}
          render={(value, record) => (
            <TextField value={formattedCurrencyAmount(value, 2, record.currency)} />
          )}
        />
        <Table.Column<IMerchant>
          dataIndex="currency"
          key="currency"
          title={t('merchants.fields.currency')}
        />
        <Table.Column<IMerchant>
          dataIndex="flexpay"
          key="flexpay"
          title={t('merchants.fields.flexpay')}
          render={(value) => <BooleanTag value={value} />}
        />
        {canChangeAntifraudEnabled?.can && (
          <Table.Column<IMerchant>
            dataIndex="antifraud_enabled"
            key="antifraud_enabled"
            title={t('merchants.fields.antifraud_enabled')}
            render={(value) => <BooleanTag value={value} />}
          />
        )}
        {canChangeAurisShopId?.can && (
          <Table.Column<IMerchant>
            dataIndex="auris_shop_id"
            key="auris_shop_id"
            title={t('merchants.fields.auris_shop_id')}
            render={(value) => <TextField value={value} />}
          />
        )}
        {canChangeDisputesEnabled?.can && (
          <Table.Column<IMerchant>
            dataIndex="disputes_enabled"
            key="disputes_enabled"
            title={t('merchants.fields.disputes_enabled')}
            render={(value) => <BooleanTag value={value} />}
          />
        )}
        <Table.Column<IMerchant>
          dataIndex="id"
          key="id"
          title={t('merchants.fields.id')}
          render={(value) => <ShortId value={value} />}
        />
        <Table.Column<IMerchant>
          title="Actions"
          dataIndex="actions"
          render={(_, record) => (
            <Space>
              <ShowButton size="small" recordItemId={record.id} />
            </Space>
          )}
        />
      </Table>
    </List>
  )
}
