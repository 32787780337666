import { useTranslate } from '@refinedev/core'
import { useSelect } from '@refinedev/antd'
import { DatePicker, Form, FormProps, Input, InputNumber, Select } from 'antd'

import { IAgent, IMerchant, IPartner, IGateway } from 'interfaces'
import { FormInstance } from 'antd/lib/form/Form'
import { useEffect } from 'react'

export const AgreementForm: React.FC<{
  formProps: FormProps
  form: FormInstance
  initialValues: Record<string, any>
}> = ({ formProps, form, initialValues }) => {
  const t = useTranslate()

  const type = Form.useWatch('type', form)
  const valid_from_date = Form.useWatch('valid_from_date', form)

  useEffect(() => {
    form.setFieldsValue({
      valid_from: valid_from_date ? valid_from_date.format('YYYY-MM-DD') : null,
    })
  }, [valid_from_date, form])

  const { selectProps: agentsSelectProps } = useSelect<IAgent>({
    resource: 'agents',
    optionLabel: 'name',
    optionValue: 'id',
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  const { selectProps: merchantsSelectProps } = useSelect<IMerchant>({
    resource: 'merchants',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  const { selectProps: partnersSelectProps } = useSelect<IPartner>({
    resource: 'partners',
    optionLabel: 'name',
    optionValue: 'id',
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  const { selectProps: gatewaysSelectProps } = useSelect<IGateway>({
    resource: 'gateways',
    optionLabel: 'name',
    optionValue: 'id',
    filters: [
      {
        field: 'compact',
        operator: 'eq',
        value: true,
      },
    ],
    pagination: {
      current: 1,
      pageSize: 100000,
    },
  })

  return (
    <Form {...formProps} layout="vertical" initialValues={initialValues}>
      <Form.Item
        label={t('agreements.form.type.label')}
        name="type"
        rules={[
          {
            required: true,
          },
        ]}
      >
        <Select
          placeholder={t('agreements.form.type.placeholder')}
          options={[
            {
              label: t('agreements.types.agent'),
              value: 'agent',
            },
            {
              label: t('agreements.types.agent_payout'),
              value: 'agent_payout',
            },
            {
              label: t('agreements.types.merchant'),
              value: 'merchant',
            },
            {
              label: t('agreements.types.payout'),
              value: 'payout',
            },
            {
              label: t('agreements.types.partner'),
              value: 'partner',
            },
            {
              label: t('agreements.types.partner_payout'),
              value: 'partner_payout',
            },
          ]}
        />
      </Form.Item>
      {(type === 'agent' || type === 'agent_payout' || type === 'merchant' || type === 'payout') && (
        <Form.Item
          label={t('agreements.form.merchant.label')}
          name="merchant_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...merchantsSelectProps}
            allowClear
            placeholder={t('agreements.form.merchant.placeholder')}
            filterOption={(input, option: any) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
        </Form.Item>
      )}
      {(type === 'agent' || type === 'agent_payout') && (
        <Form.Item
          label={t('agreements.form.agent.label')}
          name="agent_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...agentsSelectProps}
            allowClear
            disabled={initialValues['agent_id']}
            placeholder={t('agreements.form.agent.placeholder')}
            filterOption={(input, option: any) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
        </Form.Item>
      )}
      {(type === 'partner' || type === 'partner_payout') && (
        <Form.Item
          label={t('agreements.form.partner.label')}
          name="partner_id"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <Select
            {...partnersSelectProps}
            allowClear
            disabled={initialValues['partner_id']}
            placeholder={t('agreements.form.partner.placeholder')}
            filterOption={(input, option: any) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
        </Form.Item>
      )}
      {(type === 'merchant' || type === 'payout' || type === 'partner' || type === 'partner_payout') && (
        <Form.Item
          label={t('agreements.form.gateway.label')}
          name="gateway_id"
        >
          <Select
            {...gatewaysSelectProps}
            allowClear
            disabled={initialValues['gateway_id']}
            placeholder={t('agreements.form.gateway.placeholder')}
            filterOption={(input, option: any) => {
              return (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }}
          />
        </Form.Item>
      )}
      {type && (
        <Form.Item
          label={t('agreements.fields.percent')}
          name="percent"
          rules={[
            {
              required: true,
            },
          ]}
        >
          <InputNumber
            style={{ width: '100%' }}
            type="number"
            decimalSeparator="."
            precision={2}
            min={0}
          />
        </Form.Item>
      )}
      {type && (
        <>
          <Form.Item
            label={t('agreements.form.valid_from.label')}
            name="valid_from_date"
            rules={[
              {
                required: true,
              },
            ]}
          >
            <DatePicker
              style={{ minWidth: 200 }}
              format="YYYY-MM-DD"
              placeholder={t('agreements.form.valid_from.placeholder')}
            />
          </Form.Item>
          <Form.Item name="valid_from" hidden>
            <Input />
          </Form.Item>
        </>
      )}
    </Form>
  )
}
