import React from 'react'

// It is recommended to use explicit import as seen below to reduce bundle size.
// import { IconName } from "@ant-design/icons";
import * as Icons from '@ant-design/icons'

import { Card, Divider, Timeline, Typography } from 'antd'
import { useList, useTranslate } from '@refinedev/core'
import { IOperation, IEvent } from 'interfaces'
import DateField from 'components/DateField'

const { Text } = Typography
const { CheckCircleOutlined, CommentOutlined, StopOutlined, ClockCircleOutlined } = Icons

export const OperationEvents: React.FC<{
  record: IOperation
  style: React.CSSProperties
}> = ({ record, style }) => {
  const t = useTranslate()
  const eventsListQueryResult = useList<IEvent>({
    resource: 'events',

    filters: [
      {
        field: 'operation_id',
        operator: 'eq',
        value: record.id,
      },
    ],

    pagination: {
      current: 1,
      pageSize: 10000,
    },
  })

  const timelineItems = eventsListQueryResult?.data?.data.map((event) => {
    const title = (
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
          fontSize: 14,
        }}
      >
        <Text>{t('events.action_codes.' + event.action_code)}</Text>
        <DateField value={event.created_at} />
      </div>
    )

    let dot
    let color
    if (event.action_code === 'operations.approve') {
      dot = <CheckCircleOutlined />
      color = 'green'
    }
    if (event.action_code === 'operations.reject') {
      dot = <StopOutlined />
      color = 'red'
    }
    if (event.action_code === 'operations.update') {
      dot = <CommentOutlined />
    }
    if (event.action_code === 'operations.renew') {
      dot = <ClockCircleOutlined />
    }

    return (
      <Timeline.Item key={event.id} dot={dot} color={color}>
        <Card title={title} size={'small'} bordered={false} type={'inner'}>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {event.payload.comment && (
              <>
                <Text code>{event.payload.comment}</Text>
                <Divider style={{ margin: '10px 0px' }} />
              </>
            )}
            {event.payload.proposed_amount && (
              <>
                <Text code>{event.payload.proposed_amount}</Text>
                <Divider style={{ margin: '10px 0px' }} />
              </>
            )}
            {event.payload.previous_approved_amount && (
              <>
                <Text style={{ textDecoration: 'line-through' }}>
                  {event.payload.previous_approved_amount}
                </Text>
                <Text>{event.payload.approved_amount}</Text>
              </>
            )}
            <Text>{event.user?.email}</Text>
          </div>
        </Card>
      </Timeline.Item>
    )
  })

  return timelineItems?.length ? (
    <Card title={t('operations.titles.events')} style={style} size="small">
      <Timeline>{timelineItems}</Timeline>
    </Card>
  ) : (
    <></>
  )
}
