import { EyeOutlined } from '@ant-design/icons'

import { Button, Modal, Typography, Timeline, Descriptions, Card, Spin } from 'antd'

import { useApiUrl, useCustom, useTranslate } from '@refinedev/core'

import { IOperationView, IOperationViewEvent } from 'interfaces'
import { useState } from 'react'
import DateField from 'components/DateField'
import { formattedAmount, formattedCardNumber } from 'utils'
import BooleanTag from 'components/BooleanTag'

const CardContent: React.FC<{ event: IOperationViewEvent }> = ({ event }) => {
  let content = <></>
  switch (event.kind) {
    case 'operation':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'status'}>{event.data.status}</Descriptions.Item>
        </Descriptions>
      )
      break
    case 'operations.manually_approve_with_sms':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'approved_amount'}>
            {formattedAmount(event.data.approved_amount)}
          </Descriptions.Item>
          <Descriptions.Item label={'user'}>{event.data.user.email}</Descriptions.Item>
        </Descriptions>
      )
      break
    case 'operations.update_payment_requisite':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'user'}>{event.data.user.email}</Descriptions.Item>
          <Descriptions.Item label={'card_number'}>
            {formattedCardNumber(event.data.card_number)}
          </Descriptions.Item>
        </Descriptions>
      )
      break
    case 'sms':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'body'}>{event.data.body}</Descriptions.Item>
          <Descriptions.Item label={'phone'}>{event.data.phone}</Descriptions.Item>
          <Descriptions.Item label={'from_push'}>
            {event.data.from_push}
          </Descriptions.Item>
          <Descriptions.Item label={'from_livecheck'}>
            {event.data.from_livecheck}
          </Descriptions.Item>
          <Descriptions.Item label={'from_fetch_all'}>
            {event.data.from_fetch_all}
          </Descriptions.Item>
          <Descriptions.Item label={'app_version'}>
            {event.data.app_version}
          </Descriptions.Item>
        </Descriptions>
      )
      break
    case 'feed_item':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'kind'}>{event.data.kind}</Descriptions.Item>
          <Descriptions.Item label={'amount'}>
            {formattedAmount(event.data.amount)}
          </Descriptions.Item>
          <Descriptions.Item label={'commission'}>
            {formattedAmount(event.data.commission)}
          </Descriptions.Item>
          <Descriptions.Item label={'balance'}>
            {formattedAmount(event.data.balance)}
          </Descriptions.Item>
          <Descriptions.Item label={'shift'}>
            {`${event.data.shift.partner.name} (№${event.data.shift.number})`}
          </Descriptions.Item>
        </Descriptions>
      )
      break
    case 'payment_requisite.temporary_unavailable':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'amount'}>
            {formattedAmount(event.data.amount)}
          </Descriptions.Item>
          <Descriptions.Item label={'gateway_id'}>
            {event.data.gateway_id}
          </Descriptions.Item>
        </Descriptions>
      )
      break
    case 'payment_requisite_block':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'card_number'}>
            {formattedCardNumber(event.data.card_number)}
          </Descriptions.Item>
          <Descriptions.Item label={'block_from'}>
            {<DateField value={event.data.block_from} format="DD.MM.YYYY, HH:mm:ss" />}
          </Descriptions.Item>
          <Descriptions.Item label={'block_to'}>
            {<DateField value={event.data.block_to} format="DD.MM.YYYY, HH:mm:ss" />}
          </Descriptions.Item>
          <Descriptions.Item label={'flexpay'}>
            <BooleanTag value={event.data.flexpay} />
          </Descriptions.Item>
        </Descriptions>
      )
      break
    case 'operations.create':
      content = (
        <Descriptions column={1}>
          <Descriptions.Item label={'user'}>{event.data.user.email}</Descriptions.Item>
        </Descriptions>
      )
      break
  }

  return content
}

const Info: React.FC<{ id: string }> = ({ id }) => {
  const apiUrl = useApiUrl()
  const t = useTranslate()

  const { data: remoteData, isLoading } = useCustom<IOperationView>({
    url: `${apiUrl}/operations/${id}/view`,
    method: 'get',
    queryOptions: {
      queryKey: ['operations', 'info'],
    },
  })

  const operation = remoteData?.data.operation

  return operation && !isLoading ? (
    <>
      {/* <Typography.Title level={2}>Заказ</Typography.Title>
      <Descriptions column={1}>
        <Descriptions.Item label={'kind'}>{operation.kind}</Descriptions.Item>
        <Descriptions.Item label={'status'}>
          <OperationStatus status={operation.status} />
        </Descriptions.Item>
        <Descriptions.Item label={'idempotency_key'}>
          {operation.idempotency_key}
        </Descriptions.Item>
        <Descriptions.Item label={'amount'}>
          {formattedAmount(operation.amount)}
        </Descriptions.Item>
        <Descriptions.Item label={'approved_amount'}>
          {formattedAmount(operation.approved_amount)}
        </Descriptions.Item>
      </Descriptions> */}
      <Typography.Title level={2}>История</Typography.Title>
      <Timeline
        items={
          remoteData?.data.events.map((event) => ({
            dot: <></>,
            children: (
              <Card
                style={{ marginLeft: -30 }}
                title={t('operations.view.events.' + event.kind)}
                extra={
                  <DateField
                    value={event.timestamp}
                    format="DD.MM.YYYY, HH:mm:ss"
                    style={{ fontSize: 12 }}
                  />
                }
                size="small"
              >
                <CardContent event={event} />
              </Card>
            ),
          })) || []
        }
      />
    </>
  ) : (
    <div style={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
      <Spin tip="Loading" size="large" />
    </div>
  )
}

export const OperationView: React.FC<{ id: string }> = ({ id }) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const showModal = () => {
    setIsModalOpen(true)
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Button icon={<EyeOutlined />} size="small" onClick={showModal} />
      <Modal open={isModalOpen} onCancel={closeModal} footer={null} destroyOnClose={true}>
        <Info id={id}></Info>
      </Modal>
    </>
  )
}
