import { IResourceComponentsProps, useTranslate } from '@refinedev/core'
import { List, TextField, TagField, useTable } from '@refinedev/antd'
import { Table } from 'antd'
import { IGateway } from 'interfaces'
import { ShortId } from 'components/ShortId'

export const GatewaysList: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { tableProps } = useTable<IGateway>()

  return (
    <List>
      <Table
        {...tableProps}
        rowKey="id"
        pagination={{
          ...tableProps.pagination,
          pageSize: 10,
          position: ['bottomLeft'],
          size: 'small',
        }}
      >
        <Table.Column
          dataIndex="id"
          key="id"
          title={t('gateways.fields.id')}
          render={(value) => <ShortId value={value} />}
        />
        <Table.Column
          dataIndex="name"
          key="name"
          title={t('gateways.fields.name')}
          render={(value) => <TextField value={value} />}
        />
        <Table.Column
          dataIndex="currency"
          key="currency"
          title={t('gateways.fields.currency')}
        />
        <Table.Column
          dataIndex="status"
          key="status"
          title={t('gateways.fields.status')}
          render={(value) => <TagField value={value} />}
        />
      </Table>
    </List>
  )
}
