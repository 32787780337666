import { IResourceComponentsProps, useCan, useMany, useTranslate } from '@refinedev/core'
import { Table, Space, TableProps, Skeleton } from 'antd'
import { IDealing, IMerchant, IPartner, IUser } from 'interfaces'
import UserDealing from 'components/UserDealing'
import AddUserDealingButton from 'components/AddUserDealingButton'
import { compactArray } from 'utils'
import { ShortId } from 'components/ShortId'
import { EditButton, ShowButton } from '@refinedev/antd'
import BooleanTag from 'components/BooleanTag'

const UsersTable: React.FC<
  IResourceComponentsProps & { tableProps: TableProps<IUser>; rolesReadonly?: boolean }
> = ({ tableProps, rolesReadonly = false }) => {
  const t = useTranslate()

  const merchantsIds = compactArray(
    tableProps?.dataSource?.flatMap((item) =>
      item.dealings.map((dealings) => dealings.merchant_id)
    ) ?? []
  )

  const { data: merchantsData, isLoading: merchantsLoading } = useMany<IMerchant>({
    resource: 'merchants',
    ids: merchantsIds,
    meta: {
      filters: [
        {
          field: 'compact',
          operator: 'eq',
          value: true,
        },
      ],
    },
    queryOptions: {
      enabled: merchantsIds.length > 0,
    },
  })

  const partnersIds = compactArray(
    tableProps?.dataSource?.flatMap((item) =>
      item.dealings.map((dealings) => dealings.partner_id)
    ) ?? []
  )

  const { data: partnersData, isLoading: partnersLoading } = useMany<IPartner>({
    resource: 'partners',
    ids: partnersIds,
    queryOptions: {
      enabled: !!(partnersIds.length > 0),
    },
  })

  const { data: canCreateDealing } = useCan({
    resource: 'dealings',
    action: 'create',
  })

  const { data: canListDealings } = useCan({
    resource: 'dealings',
    action: 'list',
  })

  return (
    <Table
      {...tableProps}
      size="small"
      rowKey="id"
      pagination={
        tableProps.pagination && (tableProps.pagination?.total ?? 0) > 10
          ? {
              ...tableProps.pagination,
              pageSize: 10,
              position: ['bottomLeft'],
              size: 'small',
            }
          : false
      }
    >
      <Table.Column<IUser>
        dataIndex="id"
        ellipsis
        width={100}
        key="id"
        title={t('users.fields.id')}
        render={(value) => <ShortId value={value} />}
      />
      <Table.Column<IUser>
        dataIndex="email"
        ellipsis
        key="email"
        width={250}
        title={t('users.fields.email')}
      />
      <Table.Column<IUser>
        dataIndex="two_factor_verification_enabled"
        ellipsis
        key="two_factor_verification_enabled"
        width={250}
        title={t('users.fields.two_factor_verification_enabled')}
        render={(value) => <BooleanTag value={value} />}
      />
      {canListDealings?.can && (
        <Table.Column<IUser>
          dataIndex="dealings"
          key="dealings"
          title={t('users.fields.dealings')}
          render={(value: IDealing[], record) => {
            if (
              (merchantsIds.length && merchantsLoading) ||
              (partnersIds.length && partnersLoading)
            ) {
              return <Skeleton.Input active />
            }

            return (
              <Space wrap>
                {value.map((dealing) => (
                  <UserDealing
                    readonly={rolesReadonly}
                    key={dealing.id}
                    dealing={dealing}
                    merchantsData={merchantsData}
                    partnersData={partnersData}
                  />
                ))}
                {canCreateDealing?.can && !rolesReadonly && (
                  <AddUserDealingButton id={record.id} />
                )}
              </Space>
            )
          }}
        />
      )}
      <Table.Column<IUser>
        dataIndex="actions"
        title={t('users.fields.actions')}
        render={(_, record) => (
          <Space>
            <ShowButton resource="users" hideText size="small" recordItemId={record.id} />
            <EditButton resource="users" hideText size="small" recordItemId={record.id} />
          </Space>
        )}
      />
    </Table>
  )
}

export default UsersTable
