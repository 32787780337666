import React from 'react'
import { CreateButton, useModalForm, useTable } from '@refinedev/antd'
import { IAgreement } from 'interfaces'
import AgreementsTable from '../agreements/list_table'
import { Modal } from 'antd'

import { AgreementForm } from 'pages/agreements/form'

export const AgreementsList: React.FC<{ agentId: string }> = ({ agentId }) => {
  const { tableProps } = useTable<IAgreement>({
    resource: 'billing/agreements',
    filters: {
      permanent: [
        {
          field: 'agent_id',
          operator: 'eq',
          value: agentId,
        },
      ],
    },
  })

  const {
    modalProps: createModalProps,
    formProps: createFormProps,
    show: createModalShow,
    form,
  } = useModalForm<IAgreement>({
    action: 'create',
    resource: 'billing/agreements',
    redirect: false,
  })

  return (
    <>
      <CreateButton onClick={() => createModalShow()} style={{ marginBottom: 16 }} />
      <AgreementsTable tableProps={tableProps}></AgreementsTable>
      <Modal {...createModalProps}>
        <AgreementForm
          formProps={createFormProps}
          form={form}
          initialValues={{ type: 'agent', agent_id: agentId }}
        />
      </Modal>
    </>
  )
}
