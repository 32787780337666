import React from 'react'
import { IResourceComponentsProps, useOne, useShow, useTranslate } from '@refinedev/core'
import { Show } from '@refinedev/antd'
import { Skeleton, Space, Tag, Typography } from 'antd'
import { IShift, IPartner } from 'interfaces'

import { FeedsList } from './feeds_list'
import { DatesInterval } from 'components/DatesInterval'

const { Text } = Typography

export const ShiftShow: React.FC<IResourceComponentsProps> = () => {
  const t = useTranslate()
  const { queryResult } = useShow<IShift>()
  const { data } = queryResult
  const shift = data?.data

  const { data: partnerData } = useOne<IPartner>({
    resource: 'partners',
    //@ts-ignore
    id: shift?.partner_id,
    queryOptions: {
      enabled: shift !== undefined,
    },
  })

  return (
    <>
      <Show
        contentProps={{ style: { display: 'none' } }}
        headerButtons={
          shift ? (
            <Tag style={{ fontSize: '14px' }}>
              {t('shifts.statuses.' + shift?.status)}
            </Tag>
          ) : (
            <Skeleton.Input active />
          )
        }
        title={
          shift ? (
            <Space direction="vertical">
              <Text>
                Смена №{shift.number} ({partnerData?.data.name})
              </Text>
              <Text style={{ fontSize: 14 }}>
                <DatesInterval start={shift.started_at} end={shift.finished_at} />
              </Text>
            </Space>
          ) : (
            <Skeleton.Input active />
          )
        }
        goBack={false}
      ></Show>
      {shift && <FeedsList shift={shift} />}
    </>
  )
}
